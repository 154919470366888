import React, { useState } from "react";
import styles from "./styles.module.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { FaPhoneAlt } from "react-icons/fa";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Button, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import ReCAPTCHA from "react-google-recaptcha";
import { publicRequest } from "../../requestMethod";
import { Link } from "react-router-dom";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [messages, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [loading,setLoading]=useState(false)
  const [recaptchaValue, setCaptchaValue] = useState(null);

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name || !email || !messages || !phone) {
      message.error("Please fill in all fields.");
      return;
    }

    const formData = { name, email, message: messages, phone };

    try {
      setLoading(true)
      publicRequest
        .post(`/api/task/createContact`, formData)
        .then((res) => {
          message.success("Message sent successfully!");
          setName("");
          setEmail("");
          setMessage("");
          setPhone("");
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.error("Error:", err);
          message.error("An error occurred. Please try again");
        });
    } catch (error) {
      setLoading(false)
      console.error("Error:", error);
      message.error("An error occurred. Please try again");
    }
  };
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 22.694153, // Replace with the actual latitude of your office location
    lng: 88.462460, // Replace with the actual longitude of your office location
  };

  return (
    <>
      <div className={styles.contactUs}>
        <div className={styles.contactForm}>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or inquiries, feel free to reach out to
            us. We will get back to you as soon as possible.
          </p>

          <form>
            <div className={styles.formGroup}>
              <label htmlFor="name">Your Name*</label>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                id="name"
                name="name"
                placeholder="Your Name"
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="email">Your Email*</label>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                id="email"
                name="email"
                placeholder="Your Email"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email">Your Phone*</label>
              <Input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="number"
                id="phone"
                name="phone"
                placeholder="Your phone"
                required
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="message">Your Message*</label>
              <TextArea
                value={messages}
                onChange={(e) => setMessage(e.target.value)}
                id="message"
                name="message"
                placeholder="Type your message here..."
                required
              />
            </div>

            <div className={styles.formGroup}>
              <ReCAPTCHA
                sitekey="6LejCHcpAAAAAAdacfqKzBxvZ--b-3kIZq6-HGLl"
                onChange={handleRecaptchaChange}
              />
            </div>

            <Button
              loading={loading}
              disabled={!recaptchaValue}
              onClick={handleSubmit}
              type="primary"
              className={styles.submitButton}
            >
              Let's Innovate{" "}
            </Button>
          </form>
        </div>

        <div className={styles.contactDetails}>
          <h2>Get in Touch</h2>
          <p>Feel free to contact us using the information below:</p>

          <div className={styles.contactInfo}>
            <div>
              <strong>
                {" "}
                <span className={styles.icon}>
                  <FaMapMarkerAlt />
                </span>
                Address:
              </strong>
              <p>Fairy Tower, 181/1/49, Sodepur Rd, Basunagar, Madhyamgram, Kolkata, West Bengal 700129</p>
            </div>

            <div>
              <strong>
                <span className={styles.icon}>
                  {" "}
                  <FiMail />
                </span>
                Email:
              </strong>
              <Link className={styles.link} to="mailto:online.webcrafto@gmail.com">online.webcrafto@gmail.com</Link>
            </div>

            <div>
              <strong>
                {" "}
                <span className={styles.icon}>
                  <FaPhoneAlt />
                </span>
                Phone:
              </strong>
              <Link className={styles.link} to="tel:+919330159522">+91 9330159522</Link>
            </div>
          </div>
          <div className={styles.map}>
            <LoadScript googleMapsApiKey="AIzaSyBfkkKGSZZ4Y7wiFpo09j77-hLjq3AzPVY">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={15}
              >
                <Marker position={center} />
              </GoogleMap>
            </LoadScript>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
