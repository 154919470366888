import React, { useState } from "react";
import styles from "./styles.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import DrawerList from "./Drawer";

const Navbar = () => {
  const page = useLocation().pathname;
  const navigate = useNavigate();
  return (
    <nav className={styles.nav}>
      <div onClick={() => navigate("/")} className={styles.logo}>
        Webcrafto
      </div>
      <div className={styles.list}>
        <span
          onClick={() => {
            navigate("/");
          }}
          id={page === "/" ? styles.active : null}
        >
          HOME
        </span>
        <span
          onClick={() => {
            navigate("/About");
          }}
          id={page === "/About" ? styles.active : null}
        >
          ABOUT US
        </span>
        <span
          onClick={() => {
            navigate("/Service");
          }}
          id={page === "/Service" ? styles.active : null}
        >
          SERVICES
        </span>
        <span
          onClick={() => {
            navigate("/Careers");
          }}
          id={page === "/Careers" ? styles.active : null}
        >
          CAREERS
        </span>
        <span
          onClick={() => {
            navigate("/Contact");
          }}
          id={page === "/Contact" ? styles.active : null}
        >
          CONTACT
        </span>
      </div>
      <div className={styles.drawer}>
        <DrawerList />
      </div>
    </nav>
  );
};

export default Navbar;
