import axios from "axios";
// const user=JSON.parse(localStorage.getItem("refereeToken"))

const user = getTokenFromCookie();
function getTokenFromCookie() {
  const cookieString = document.cookie;
  const cookies = cookieString.split(";");

  for (const cookie of cookies) {
    const [name, value] = cookie.split("=").map((c) => c.trim());
    if (name === "token") {
      return value;
    }
  }

  return null; // Return null if the token cookie is not found
}

const BASE_URL = "https://webcrafto-37pa.onrender.com/";
// const BASE_URL = "http://localhost:6001";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Basic V0VCQ1JBRlRPOldFQkNSQUZUT1FXRVJUWVVJT1A=",
  },
});
export const fileRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: "Basic V0VCQ1JBRlRPOldFQkNSQUZUT1FXRVJUWVVJT1A=",
  },
});
export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + user,
  },
});
