import React, { useState } from 'react'
import styles from "./styles.module.css"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import img2 from '../../../Assets/Home/faq.svg';



const Faq = () => {
    const [accordions, setAccordions] = useState([
        { id: 'input1', question: 'What sets your startup apart from existing solutions in the market?', answer: 'Our startup distinguishes itself through a unique combination of innovation, user-centric design, and a deep understanding of market needs. We have conducted thorough market research to identify gaps and pain points, allowing us to develop a solution that not only addresses existing challenges but also introduces novel features that provide a competitive edge. Our commitment to continuous improvement and adaptability ensures that we stay ahead of the curve in a rapidly evolving business landscape.' },
        { id: 'input2', question: 'How do you ensure the security and privacy of user data in your products or services?', answer: 'At our startup, the security and privacy of user data are of utmost importance. We implement industry-leading encryption standards to safeguard sensitive information, conduct regular security audits, and adhere to strict data protection regulations. Our team follows best practices in data management and undergoes regular training to stay informed about the latest security threats. Additionally, we provide transparent privacy policies to our users, outlining how their data is collected, processed, and protected throughout their interaction with our products or services.' },
        { id: 'input3', question: 'What is your go-to-market strategy to acquire and retain customers?', answer: 'Our go-to-market strategy revolves around a multi-faceted approach aimed at reaching and engaging our target audience effectively. We employ a mix of digital marketing, content creation, and social media outreach to build brand awareness. Strategic partnerships with key industry players help us extend our reach and enhance our credibility. Customer retention is equally vital, and we achieve this through exceptional customer support, regular product updates, and loyalty programs. By continually refining our strategy based on customer feedback and market trends, we ensure sustainable growth and customer satisfaction.' },
        { id: 'input4', question: 'How does your startup contribute to social and environmental responsibility?', answer: `Social and environmental responsibility are integral aspects of our startup's ethos. We are committed to minimizing our environmental footprint by adopting eco-friendly practices, such as using sustainable materials and reducing energy consumption. Additionally, we actively engage in social initiatives, supporting local communities and charitable causes. Our corporate social responsibility (CSR) programs aim to create a positive impact beyond our business operations. By aligning our values with the broader goals of sustainability and community welfare, we strive to be a responsible and conscientious contributor to society.` },
    ]);

    const toggleAccordion = (id) => {
        setAccordions((prevState) =>
            prevState.map((accordion) => ({
                ...accordion,
                isOpen: accordion.id === id ? !accordion.isOpen : false,
            }))
        );
    };
  return (
    <div className={styles.ful}>
    <div className={styles.bgWhite}>
      <h3 className={styles.heading1}>Frequently Asked Questions</h3>
      <div className={styles.freq}>
        <div className={styles.first1}>
          <img src={img2} alt="" className={styles.imgage2}  />
        </div>
        <div className={styles.second1}>
          <form className={styles.customForm}>
            {accordions.map((accordion) => (
              <div className={`${styles.accordion} ${accordion.isOpen ? styles.open : ''}`} key={accordion.id}>
                <div className={styles.accordionHeader} onClick={() => toggleAccordion(accordion.id)}>
                  <span>{accordion.question}</span>
                  {accordion.isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                </div>
                {accordion.isOpen && (
                  <div className={styles.accordionContent}>{accordion.answer}</div>
                )}
              </div>
            ))}
            <button className={styles.btn} type="submit">
              View More
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default Faq