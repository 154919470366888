import img from '../../Assets/About/aboutimg.png';
import React from 'react'

import styles from "./styles.module.css"

const About = () => {
    return (
        <>
            <div className={styles.aboutUs}>
                <div className={styles.aboutContent}>
                    <h2>About Us</h2>
                    <p>
                        Welcome to Webcrafto, your go-to destination for top-notch mobile application development.
                        At Webcrafto, we believe in combining web expertise with craftsmanship to elevate your digital presence.
                        
                    </p>

                    <div className={styles.aboutValues}>
                        <h3>Our Values</h3>
                        <ul>
                            <li>Quality craftsmanship in every project</li>
                            <li>Customer satisfaction is our priority</li>
                            <li>Innovation and creativity drive us</li>
                        </ul>
                    </div>
                </div>

                <div className={styles.aboutImage}>
                    <img src={img} alt="About Us Image" />
                </div>
            </div>
            <div className={styles.secondContainer}>
                <h1>Why Choose Us?</h1>
                <div className={styles.innerDiv}>
                    <div>
                        <h4>Experience and Expertise</h4>
                        <p>We have a team of highly skilled and experienced web designers who have been working in the industry for many years. We have successfully completed numerous web design projects for clients from various industries. Our expertise allows us to understand your unique requirements and deliver tailored solutions.</p>

                    </div>
                    <div>
                        <h4>Creative Designs </h4>
                        <p>We take pride in our ability to create visually appealing and engaging website designs. Our team stays updated with the latest design trends and techniques to ensure that your website stands out from the competition. We work closely with you to understand your brand identity and incorporate it into the design, resulting in a unique website.</p>

                    </div>
                    <div>
                        <h4>User-Centered Approach</h4>
                        <p>We believe that a successful website should not only look good but also provide an excellent user experience. We employ a user-centered design approach, focusing on usability, accessibility, and intuitive navigation. Our goal is to create websites that are easy to use and navigate, ensuring that your visitors have a positive experience.</p>

                    </div>
                    <div>
                        <h4>Mobile-Friendly Designs</h4>
                        <p>With the increasing use of mobile devices, having a responsive and mobile-friendly website is crucial. Our web designs are built using the latest responsive design techniques, ensuring that your website looks and functions seamlessly across all devices and screen sizes. This improves user experience, boosts search engine rankings, and expands your reach to mobile users.</p>

                    </div>
                    <div>
                        <h4>SEO-Friendly Designs</h4>
                        <p>We understand the importance of SEO in driving organic traffic to your website. Our web designs are optimized for SEO, following best practices and guidelines to improve your website’s visibility in search engine results. We incorporate SEO-friendly elements such as proper URL structures, meta tags, optimized images, and clean code to enhance your website’s search engine performance.</p>

                    </div>
                    <div>
                        <h4>Client Satisfaction</h4>
                        <p>Above all, our primary focus is client satisfaction. We strive to exceed your expectations and deliver a website that not only meets your requirements but also helps you achieve your business goals. We value clear communication, timely delivery, and transparency throughout the project, ensuring that you are involved in every step of the process.</p>

                    </div>
                 

                </div>

            </div>

        </>
    )
}

export default About