import React, { useState } from "react";
import styles from "./styles.module.css";
import { Button, Input, message } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import TextArea from "antd/es/input/TextArea";
import { publicRequest } from "../../requestMethod";
import img1 from "../../Assets/Carrer/carrer.webp"

const Portfolio = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [document, setDocument] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setCaptchaValue] = useState(null);

  const handleRecaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name || !email || !document || !phone) {
      message.error("Please fill all required fields.");
      return;
    }

    const formData = { name, email, document, phone };

    try {
      setLoading(true);
      publicRequest
        .post(`/api/task/createCareer`, formData)
        .then((res) => {
          message.success("Message sent successfully!");
          setName("");
          setEmail("");
          setDocument("");
          setPhone("");
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.error("Error:", err);
          message.error("An error occurred. Please try again");
        });
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      message.error("An error occurred. Please try again");
    }
  };
  return (
    <div className={styles.careers}>
      <div className={styles.careerTop}>
        <div className={styles.careerDetails}>
          <span>BE A PART OF</span>
          <span>Our Successful Journey</span>
          <p>
            Join us on this exciting journey as we shape the future together.
            Explore our current job openings below and embark on a career with
            endless possibilities at Webcrafto.
          </p>
        </div>
        <div className={styles.careerImg}>
          <img
            src={img1}
            alt=""
          />
        </div>
      </div>
      <div className={styles.second}>
        <div className={styles.formUpper}>
          <span>Why Work with Webcrafto?</span>
          <p>
            Webcrafto promotes a culture of impacting users’ lives with our
            innovations. And we are looking for people who share, understand our
            vision and contribute to it. Our company facilitates an environment
            with open communication, togetherness and equal opportunities.
          </p>
          <p>
            We’re passionate about constantly expanding and forever keen to find
            dynamic talent. Join us to level up not just the company’s
            benchmarks but your own as well.
          </p>
        </div>
        <div className={styles.form}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Your Name"
          />
          <Input
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your Email"
          />
          <Input
            value={phone}
            type="number"
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Your Contact Number"
          />
          <Input
            value={document}
            onChange={(e) => setDocument(e.target.value)}
            placeholder="Your Resume Drive link"
          />
          <ReCAPTCHA
            sitekey="6LejCHcpAAAAAAdacfqKzBxvZ--b-3kIZq6-HGLl"
            onChange={handleRecaptchaChange}
          />
          <Button
            loading={loading}
            disabled={!recaptchaValue}
            onClick={handleSubmit}
            type="primary"
          >
            Can't Wait To Join
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
