import React, { useState } from "react";
import { Drawer } from "antd";
import { IoMenu } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const DrawerList = () => {
  const navigate = useNavigate();
  const page = useLocation().pathname;
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <span onClick={showDrawer}>
        <IoMenu fontSize={40} />
      </span>
      <Drawer placement="left" width={500} onClose={onClose} open={open}>
        <div className={styles.list2}>
          <span
            onClick={() => {
              navigate("/");
              setOpen(false);
            }}
            id={page === "/" ? styles.active : null}
          >
            HOME
          </span>
          <span
            onClick={() => {
              navigate("/About");
              setOpen(false);
            }}
            id={page === "/About" ? styles.active : null}
          >
            ABOUT US
          </span>
          <span
            onClick={() => {
              navigate("/Service");
              setOpen(false);
            }}
            id={page === "/Service" ? styles.active : null}
          >
            SERVICES
          </span>
          <span
            onClick={() => {
              navigate("/Careers");
              setOpen(false);
            }}
            id={page === "/Careers" ? styles.active : null}
          >
            CAREERS
          </span>
          <span
            onClick={() => {
              navigate("/Contact");
              setOpen(false);
            }}
            id={page === "/Contact" ? styles.active : null}
          >
            CONTACT
          </span>
        </div>
      </Drawer>
    </>
  );
};
export default DrawerList;
