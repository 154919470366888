import React from 'react'
import svg1 from "../../../Assets/Home/svg1.svg"
import svg2 from "../../../Assets/Home/svg2.svg"
import svg3 from "../../../Assets/Home/sv3.jpg"
import svg4 from "../../../Assets/Home/svg4.svg"
import svg5 from "../../../Assets/Home/svg5.svg"
import styles from "./styles.module.css"

const Slider = () => {
    const customDivs = [
        { backgroundColor: '#E1F1FF', title: 'Design Services', img: svg1 },
        { backgroundColor: '#EBE7F6', title: 'App Development ', img: svg2 },
        { backgroundColor: '#F6F4E6', title: 'UI/UX Design ', img: svg3 },
        { backgroundColor: '#E0F1F5', title: 'Web Development', img: svg4 },
        { backgroundColor: '#F6ECE7', title: 'E commerce ', img: svg5 },
       
    ];
    return (
        <div className={styles.parent}>
            <div className={styles.heading}>
                <div className={styles.head}>
                    <div className={styles.horLine}></div>
                    <h4 className={styles.heading1}>Solutions we offer</h4>
                    <div className={styles.horLine}></div>
                </div>
                <p className={styles.para}>Unlock your business potential with our cutting-edge software solutions.<br /> Partner with us to stay ahead, save costs, and adapt quickly to market changes.</p>
            </div>
            <div className={styles.sliderDiv}>
                {
                    customDivs.map((el) => {
                        return (

                            <div className={styles.custom_div} style={{
                                backgroundColor: el.backgroundColor
                            }}>
                                <img src={el.img} alt="Your SVG" width="50" height="50" />
                                <h2 className={styles.title}>{el.title}</h2>
                            </div>

                        )
                    })
                }
            </div>
        </div >

    )
}

export default Slider