import React from 'react'
import img from '../../Assets/About/aboutimg.png';
import svg1 from '../../Assets/About/absvg1.svg';
import svg2 from '../../Assets/About/absvg2.svg';
import e1 from '../../Assets/Home/e1.png';
import e2 from '../../Assets/Home/e2.png';
import e3 from '../../Assets/Home/e3.png';
import styles from "./styles.module.css"
import Exchange from './Exchange/Exchange';
import Faq from './Faq/Faq';
import Slider from './Slider/Slider';

const Home = () => {

    function Structure({ name, destination, img }) {
        return (
            <div className={styles.testimo}>
                <div>
                    <img src={img} alt="" height="200px" width="200px" />
                </div>
                <p className="name">{name}</p>
                <p className="destination">{destination}</p>
            </div>
        );
    }




    const testData = [
        {
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
            name: "Promod sharma",
            destination: "UI designer",
        },
        {
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
            name: "Promod sharma",
            destination: "UI designer",
        },
        {
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
            name: "Promod sharma",
            destination: "UI designer",
        },
        {
            img: "https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
            name: "Promod sharma",
            destination: "UI designer",
        },
    ];

    return (
        <div>
            <div className={styles.hero}>
                <div className={styles.subDiv}>
                    <div className={styles.dDiv}>
                        <h3>WE CRAFT
                            <br />  SUCCESS STRATEGY</h3>
                        <p>
                            WE CRAFT AN IMPECCABLE SUCCESS <br /> STRATEGY FOR YOUR BUSINESS <br /> WITH OUR EXPERTS</p>
                    </div>
                    <div className={styles.imageContainer}>
                        <img src={e1} alt="e1" className={styles.image} height='250px' width='400px' />
                        <img src={e2} alt="e2" className={styles.image} height='100px' width='320px' />
                        <img src={e3} alt="e3" className={styles.image} height='100px' width='300px' />
                    </div>
                </div>
            </div>

            <Slider />
            <div className={styles.main}>
                <div className={styles.imgdiv}>
                    <img src={img} alt="About Image" className={styles.aboutImage} />
                </div>
                <div className={styles.info}>
                    <div className={styles.headDiv}>
                        <h4>About</h4>
                        <p>
                        Webcrafto: Where digital dreams are sculpted into reality, weaving innovation with precision to architect a bespoke online presence that resonates with your vision and captivates your audience.
                        </p>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.iconContainer}>
                            <img src={svg1} alt="Icon 1" className={styles.iicon} />
                        </div>
                        <div className={styles.secDiv}>
                            <h6>Why us?</h6>
                            <p>
                            At Webcrafto, we're your digital artisans, blending creativity and expertise to craft unique online solutions that exceed expectations. With us, expect innovation, reliability, and a seamless journey to success.
                            </p>
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.iconContainer}>
                            <img src={svg2} alt="" className={styles.iicon} />
                        </div>
                        <div className={styles.tDiv}>
                            <h6>Our Expert</h6>
                            <p>
                            At Webcrafto, our team comprises seasoned professionals dedicated to mastering the latest technologies and trends. With a passion for innovation and a commitment to excellence, our experts ensure your project is in the hands of skilled craftsmen who deliver exceptional results, every time.
                            </p>
                        </div>
                    </div>
                    <div className={styles.btnDiv}>
                        <button className={styles.centerButton}>Read More</button>
                    </div>
                </div>
            </div>

            {/* <div className={styles.t_main}>
                <div className={styles.header}>
                    <div className={styles.hor_line}></div>
                    <h2>Client Testimonial</h2>
                    <div className={styles.hor_line}></div>
                </div>
                <div className={styles.test_flex}>
                    {testData.map((data, index) => (
                        <Structure
                            key={index}
                            img={data.img}
                            name={data.name}
                            destination={data.destination}
                        />
                    ))}
                </div>
            </div> */}
            {/* <Exchange /> */}
            <Faq />
        </div>
    )
}

export default Home