import React from "react";
import { BiLogoFacebook, BiSolidPhone } from "react-icons/bi";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";

const Footer = () => {

  return (
    <div className={styles.f_main}>
      <div className={styles.l_main}>
        <div className={styles.logo}>Webcrafto</div>
        <p className={styles.address}>
          Fairy Tower, 181/1/49, Sodepur Rd,
          <br /> Basunagar, Madhyamgram, Kolkata, West Bengal 700129
        </p>
        <div className={styles.l_data}>
          <Link
            to="https://www.facebook.com/Webcrafto.online/"
            className={styles.iconsss}
          >
            <BiLogoFacebook fontFamily={40} />
          </Link>
          <Link
            to="https://www.instagram.com/webcrafto/"
            className={styles.iconsss}
          >
            <AiOutlineInstagram fontFamily={40} />
          </Link>
          <Link to="https://twitter.com/webcafto" className={styles.iconsss}>
            <AiOutlineTwitter fontFamily={40} />
          </Link>
        </div>
      </div>
      <div className={styles.links}>
        <div className={styles.contactLinks}>
          <h5 className={styles.heading}>Contact</h5>

          <Link
            className={styles.contactLink}
            to="https://www.linkedin.com/company/webcrafto-pvt-ltd/"
          >
            LinkedIn
          </Link>
          <Link
            className={styles.contactLink}
            to="https://twitter.com/webcafto"
          >
            Twitter
          </Link>
          <Link
            className={styles.contactLink}
            to="https://www.facebook.com/Webcrafto.online/"
          >
            Facebook
          </Link>
          <Link
            className={styles.contactLink}
            to="https://www.instagram.com/webcrafto/"
          >
            Instagram
          </Link>
        </div>

        <div className={styles.contactLinks}>
          <h5 className={styles.heading}>Services</h5>

          <Link className={styles.contactLink} to="/Service">
            Website design
          </Link>
          <Link className={styles.contactLink} to="/Service">
            App design
          </Link>
          <Link className={styles.contactLink} to="/Service">
            Web design
          </Link>
        </div>
        <div className={styles.contactLinks}>
          <h5 className={styles.heading}>About</h5>

          <Link className={styles.contactLink} to="/About">
            Our story
          </Link>
          <Link className={styles.contactLink} to="/About">
            Blog
          </Link>
          <Link className={styles.contactLink} to="/About">
            Team
          </Link>
        </div>
        <div className={styles.contactLinks}>
          <h5 className={styles.heading}>Help</h5>

          <Link className={styles.contactLink} to="/">
            FAQs
          </Link>
          <Link className={styles.contactLink} to="/Contact">
            Contact us
          </Link>
          <Link className={styles.contactLink} to="/Careers">
            Career
          </Link>
        </div>
        <div className={styles.contact}>
          <Link
            className={styles.contactLink}
            to="mailto:online.webcrafto@gmail.com"
          >
            {" "}
            <FiMail /> online.webcrafto@gmail.com
          </Link>
          <Link className={styles.contactLink} to="tel:+919330159522">
            {" "}
            <BiSolidPhone /> +91-9330159522
          </Link>
        </div>
      </div>

      <div className={styles.responsiveLinks}>
        <span className={styles.logo2}>Webcrafto</span>
        <p className={styles.address}>
          {" "}
          Fairy Tower, 181/1/49, Sodepur Rd, Basunagar, Madhyamgram, Kolkata,
          West Bengal 700129
        </p>
        <div className={styles.contact2}>
          <Link
            className={styles.contactLink2}
            to="mailto:online.webcrafto@gmail.com"
          >
            {" "}
            <FiMail /> online.webcrafto@gmail.com
          </Link>
          <Link className={styles.contactLink2} to="tel:+919330159522">
            {" "}
            <BiSolidPhone /> +91-9330159522
          </Link>
        </div>
        <div className={styles.l_data2}>
        <Link
            to="https://www.facebook.com/Webcrafto.online/"
            className={styles.iconsss}
          >
            <BiLogoFacebook fontSize={30} />
          </Link>
          <Link
            to="https://www.instagram.com/webcrafto/"
            className={styles.iconsss}
          >
            <AiOutlineInstagram fontSize={30} />
          </Link>
          <Link to="https://twitter.com/webcafto" className={styles.iconsss}>
            <AiOutlineTwitter fontSize={30} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
