import React from "react";
import styles from "./styles.module.css";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const Service = () => {
  const navigate=useNavigate()
  return (
    <div className={styles.services}>
      <h1>Our Expertise</h1>
      <p>
        We specialize in designing and developing all kinds of websites and
        e-commerce solutions that are tailored to our clients’ specific needs
        and requirements.​ Our services go beyond website development. We also
        offer website maintenance and support, as well as digital marketing
        services Like SEO, PPC, social media marketing amd much more.
      </p>
      <div className={styles.innerDiv}>
        <div className={styles.innerTopDiv}>
          <div>
            <img
              src="https://fanpsplayprodbuck.s3.ap-south-1.amazonaws.com/pexels-tranmautritam-326503.jpg"
              alt=""
            />
          </div>
          <div className={styles.detailsDiv}>
            <h4>Website Design & Development</h4>
            <p>
              We can bring your visions and ideas to life through innovative
              solutions tailored to fit the needs of your business. Professional
              looking website with easy navigation & appropriate call to action
              buttons are key to turning your visitors in to customers. Get
              world-class website at reasonable pricing, We are experts in all
              kinds of websites including landing pages & business websites.
              Know more…
            </p>

            <Button onClick={()=>navigate('/Contact')}>Talk to us</Button>
          </div>
        </div>
        <div className={styles.innerTopDiv} id={styles.reverseDiv}>
          <div className={styles.detailsDiv}>
            <h4>E-Commerce Website</h4>
            <p>
              We are experts in building powerful and scalable e-commerce store
              with beautiful design & functionality tailormade for your
              business. Whether you selling products or services, we got you
              covered. Get everything, you need to create an interactive
              e-commerce store with unmatched user experience. Know more…
            </p>

            <Button onClick={()=>navigate('/Contact')}>Talk to us</Button>
          </div>
          <div>
            <img
              src="https://fanpsplayprodbuck.s3.ap-south-1.amazonaws.com/pexels-negative-space-34577.jpg"
              alt=""
            />
          </div>
        </div>
        <div className={styles.innerTopDiv}>
          <div>
            <img
              src="https://fanpsplayprodbuck.s3.ap-south-1.amazonaws.com/pexels-photomix-company-887751.jpg"
              alt=""
            />
          </div>
          <div className={styles.detailsDiv}>
            <h4>App Development</h4>
            <p>
              We help you improve visibility of your brand and products. Reach
              out to your target audience through various channels like SEO,
              PPC, social media posts and promotions, email marketing, content
              marketing, etc. Grow your business and become a leader in your
              industry with our innovative digital marketing strategies and
              ideas. Get in touch to know more…
            </p>

            <Button onClick={()=>navigate('/Contact')}>Talk to us</Button>
          </div>
        </div>
        <div className={styles.innerTopDiv} id={styles.reverseDiv}>
          <div className={styles.detailsDiv}>
            <h4>Block Chain</h4>
            <p>
              We can bring your visions and ideas to life through innovative
              solutions tailored to fit the needs of your business. Professional
              looking website with easy navigation & appropriate call to action
              buttons are key to turning your visitors in to customers. Get
              world-class website at reasonable pricing, We are experts in all
              kinds of websites including landing pages & business websites.
              Know more…
            </p>

            <Button onClick={()=>navigate('/Contact')}>Talk to us</Button>
          </div>
          <div>
            <img
              src="https://fanpsplayprodbuck.s3.ap-south-1.amazonaws.com/pexels-morthy-jameson-9588213.jpg"
              alt=""
            />
          </div>
        </div>
        <div className={styles.innerTopDiv}>
          <div>
            <img
              src="https://fanpsplayprodbuck.s3.ap-south-1.amazonaws.com/pexels-tara-winstead-8386440.jpg"
              alt=""
            />
          </div>
          <div className={styles.detailsDiv}>
            <h4>Artificial intelligence</h4>
            <p>
            Artificial Intelligence (AI) is at the core of our app development process. We integrate AI-driven features to enhance user experiences, streamline operations, and provide personalized interactions. From chatbot offering real-time customer support to predictive analytics optimizing decision-making, our AI-powered apps learn and adapt, creating dynamic solutions that keep businesses at the forefront of technological advancement.
            </p>

            <Button onClick={()=>navigate('/Contact')}>Talk to us</Button>
          </div>
        </div>
        <div className={styles.innerTopDiv} id={styles.reverseDiv}>
          <div className={styles.detailsDiv}>
            <h4>Metaverse</h4>
            <p>
            The Metaverse is no longer a futuristic concept—it's a reality we're actively shaping. Our top mobile app development company in USA envisions, designs, and develops for this interconnected digital universe. By blending virtual and physical realities, we create apps that enable users to socialize, work, and explore in novel ways. With a deep understanding of Metaverse technologies, we build bridges between virtual worlds, revolutionizing how people interact, collaborate, and experience digital spaces.
            </p>

            <Button onClick={()=>navigate('/Contact')}>Talk to us</Button>
          </div>
          <div>
            <img
              src="https://fanpsplayprodbuck.s3.ap-south-1.amazonaws.com/pexels-fauxels-3183143.jpg"
              alt=""
            />
          </div>
        </div>
        <div className={styles.innerTopDiv}>
          <div>
            <img
              src="https://fanpsplayprodbuck.s3.ap-south-1.amazonaws.com/pexels-michelangelo-buonarroti-8728223.jpg"
              alt=""
            />
          </div>
          <div className={styles.detailsDiv}>
            <h4>AR/VR</h4>
            <p>
            Immersive experiences are at the heart of what we do. Our mobile app development company specializes in Augmented Reality (AR) and Virtual Reality (VR), crafting apps that transport users to captivating digital realms. Through seamless integration of AR/VR technologies, we bridge the gap between reality and imagination, enabling businesses to engage customers in unprecedented ways
            </p>

            <Button onClick={()=>navigate('/Contact')}>Talk to us</Button>
          </div>
        </div>
        <div className={styles.innerTopDiv} id={styles.reverseDiv}>
          <div className={styles.detailsDiv}>
            <h4>IOT</h4>
            <p>
            The Internet of Things (IoT) is transforming everyday objects into smart, interconnected devices. Our app development expertise extends to IoT, as we create apps that enable users to control and monitor their environment remotely. From smart home solutions that manage lighting and security to industrial IoT applications optimizing processes, we seamlessly connect devices, collect data, and provide user-friendly interfaces that put the power of IoT in the hands of users
            </p>

            <Button onClick={()=>navigate('/Contact')}>Talk to us</Button>
          </div>
          <div>
            <img
              src="https://fanpsplayprodbuck.s3.ap-south-1.amazonaws.com/pexels-jakub-zerdzicki-18186205.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
